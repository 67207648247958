import { javascript } from '@api/index'
import type { Icon } from '@icons/index'
import { useHydration, usePersistence } from '@stores/utils'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import UsersApi from '~/api/UsersApi'

export type UserStoreType = ReturnType<typeof useUserStore>
export const allUserStores = ref<Array<UserStoreType>>([])

export type UserDataType = Backend.Models.User & {
  custom_attributes: (Backend.Models.CustomAttribute & { folder_id: number; folder_name: string })[]
  custom_operations: Backend.Models.CustomOperation[]
  is_open_ai_enabled: boolean
  is_drive_enabled: boolean
  url_whitelist: Backend.Models.IUrlWhitelistEntry[]
  urls: {
    help: string
  }
  permissions: {
    groups: number[]
    adminForGroups: number[]
    profiles: number[]
    adminForProfiles: number[]
    managerForProfiles: number[]
    organisations: number[]
    adminForOrganisations: number[]
    systemSupport: number[]
  }
  locales: [string, string][]
  timezones: [string, string][]
  settings: {
    questionnaire_compress: boolean
    document_preview: boolean
    documents_action_filter: string | null
    sidebar_collapsed_by_default: string[]
    sidebar_collapsed_by_default_options: string[]
    disabled_emails: string[]
    disabled_emails_options: string[]
  }
  integrations: {
    integration: string
    name: string
    authorized: boolean
    authorizable: boolean
    inherited: boolean
    authorized_as: {message: string, user_name: string, user_email: string}
    urls: {
      authorize: string
      revoke: string
    }
    logo: string
  }[]
  otp_module: string
  topbar_tabs: {
    show: boolean
    label?: string
    icon?: Icon
    url: string
  }[]
  profiles: Backend.Models.Profile[]
  two_factor_qr: {
    qrcode: {
      data: {
        data: string
        mode: string
      }
      error_correct_level: number
      version: number
      module_count: number
      modules: boolean[][]
      data_list: {
        data: string
      }
      data_cache: number[]
      common_patterns: (boolean | null)[][]
    }
  }
}

export const createUserStore = (uniqueId: string | number) => {
  const userStore = defineStore('user-store-' + String(uniqueId), () => {
    const hydration = useHydration<UserDataType>(javascript.loggedUser)
    const hydrateById = (
      id: number,
      fields: Array<keyof UserDataType> = []
    ) => {
      return hydration.hydrate({ id }, fields)
    }
    allUserStores.value.push(userStore as unknown as UserStoreType)

    const attributeLibrary = hydration.hydratedComputed(
      'custom_attributes',
      (models) => {
        return models.map((model) => {
          const question = {
            att: model.name,
            desc: model.question_text,
            type: model.question_type,
            opts: {
              placeholder: model.question_hint,
              required: model.question_required,
              ...model.question_options
            },
            folder_id: model.folder_id,
            folder_name: model.folder_name
          } as unknown as Backend.Questionnaire.IQuestion 

          return window.AvvParser.normalize(
            question
          ) as Backend.Questionnaire.IQuestion & { att: string, folder_id: number, folder_name: string }
        })
      }
    )

    const attributeLibraryByAttribute = computed(() =>
      attributeLibrary.value.reduce<
        Record<string, Backend.Questionnaire.IQuestion>
      >((memo, attribute) => {
        memo[attribute.att] = attribute
        return memo
      }, {})
    )

    const operationLibrary = hydration.hydratedComputed(
      'custom_operations',
      (models) => {
        return models.map((model) =>
          Object.assign({}, model, {
            text: model.text ? Ast.stringify(model.text) : model.text
          }) as Backend.Models.CustomOperation & {text: string | undefined, folder_name: string}
        )
      }
    )

    const id = hydration.hydratedComputed('id')

    return {
      ...hydration,
      hydrateById,
      id,
      displayName: hydration.hydratedComputed('display_name'),
      openAiEnabled: hydration.hydratedComputed('is_open_ai_enabled'),
      driveEnabled: hydration.hydratedComputed('is_drive_enabled'),
      urlWhitelist: hydration.hydratedComputed('url_whitelist'),
      attributeLibrary,
      attributeLibraryByAttribute,
      operationLibrary,
      permissions: hydration.hydratedComputed('permissions'),
      urls: hydration.hydratedComputed('urls'),
      settings: hydration.hydratedComputed('settings'),
      firstName: hydration.hydratedWritableComputed('firstname'),
      lastName: hydration.hydratedWritableComputed('lastname'),
      email: hydration.hydratedWritableComputed('email'),
      locale: hydration.hydratedWritableComputed('locale'),
      locales: hydration.hydratedComputed('locales'),
      timezone: hydration.hydratedWritableComputed('time_zone'),
      timezones: hydration.hydratedComputed('timezones'),
      integrations: hydration.hydratedComputed('integrations'),
      otp_module: hydration.hydratedComputed('otp_module'),
      topbar_tabs: hydration.hydratedComputed('topbar_tabs'),
      profiles: hydration.hydratedComputed('profiles'),
      two_factor_qr: hydration.hydratedComputed('two_factor_qr'),
      ...usePersistence(
        hydration,
        ['firstname', 'lastname', 'time_zone', 'locale', 'email', 'settings'],
        (data) => UsersApi.update({ params: { id: id.value }, data: { user: data } })
      )
    }
  })
  return userStore
}

export const useUserStore = createUserStore('current')
